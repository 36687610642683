<template>
    <div class="communityBox">
        <Search />
        <div class="communityContent">
            <div class="nav">
                <div @click="goUstand">
                    <div class="img">
                        <img src="../../assets/images/community/logo.png" alt="" />
                    </div>
                    <p>审核员招募</p>
                </div>
                <div v-for="item in nav.slice(0,4)" :key="item.id" @click="navClickFun(item)">
                    <div class="img">
                        <img style="width:50px;height:50px" :src="$util.host+item.thumbnail" alt="">
                    </div>
                    <p>{{ item .name}}</p>
                </div>
                <div v-if="nav.length > 4">
                    <el-dropdown placement="bottom" trigger="click" @command="indexChange">
                        <img style="margin:17px 0 4px ;width:50px;height:50px;display:block" src="../../assets/images/serve/gd.png" alt="">
                        <span class="el-dropdown-link">
                            更多
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item" v-for="item in nav.slice(4,100)" :key="item.id">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- <div class="noneBox" v-if="showClass">
                    <p v-for="item in 3">
                        类别{{item}}
                    </p>
                    <p>我的创作中心</p>
                </div> -->
            </div>

            <div class="communityData">
                <span class="title">{{classText}}</span>
                <div class="data">
                    <div>
                        <font>主贴数：</font>
                        <span>{{articleNum}}</span>
                    </div>
                    <div>
                        <font>回帖数：</font>
                        <span>{{backNum}}</span>
                    </div>
                </div>
            </div>

            <!-- banner -->
            <div class="bannerBox">
                <el-carousel height="400px">
                    <el-carousel-item v-for="item in balnner" :key="item.id">
                        <img :src="$util.host +item.url" @click="toHerf(item)" alt="" class="bannerImg" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="recommendBox">
                <div class="recommend">
                    <div class="twoList">
                        <div class="list" v-for="item in toppingList.slice(0,3)" :key="item.id" @click="goDetail(item,item.id)">
                            <img :src="$util.host +item.cover" alt="" />
                            <div>
                                <p> {{item.name}}</p>
                                <p>发帖人：{{item.sendUserName}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="fourList">
                        <div class="list" v-for="item in toppingList.slice(3,6)" :key="item.id" @click="goDetail(item,item.id)">
                            <img :src="$util.host +item.cover" alt="" />
                            <div>
                                <p> {{item.name}}</p>
                                <p>发帖人：{{item.sendUserName}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tableBox">
                <div class="tableList">
                    <div v-for="(item, index) in tab" @click="changeTab(index)" :key="index" :class="{ activeClass: active == index }">
                        {{ item }}
                    </div>
                </div>
                <div class="wantTopost" @click="toPost">
                    【我要发帖】
                </div>
            </div>
        </div>
        <div class="tabContentBox" v-if="active == 0">
            <div class="tabContent">
                <div class="list" v-for="item in defaultList" @click="goDetail(item,item.id)" :key="item.id">
                    <img :src="$util.host +item.cover" alt="" class="img" />
                    <div>
                        <p>{{item.name}}</p>
                        <p>
                            <span>发帖人：<font>{{item.sendUserName}}</font></span> <span>{{item.createTime}}</span>
                        <p v-html="item.content" class="textContent" style="">
                        </p>

                        </p>
                        <p>
                            <span>评论：<font>{{item.commentNum}}</font></span>
                            <span>回复：<font>{{item.replyNum}}</font></span>
                        </p>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination background layout="prev, pager, next" :page-size="shopPage.pageSize" @current-change="changePage" :total="shopPage.totalRows">
                    </el-pagination>
                    <div class="total">共{{ shopPage.totalRows }}条/{{ shopPage.totalPage }}页</div>
                    <div class="jump">
                        跳转到
                        <input type="number" v-model="shopNum" />
                        页
                    </div>
                    <el-button style="
            background: #2ea7e0;
            color: #fff;
            width: 108px;
            height: 50px;
            font-size: 20px;
          " @click="goShopPage">前往</el-button>
                </div>
            </div>
        </div>
        <div class="tabContentBox" v-if="active == 1">
            <div class="tabContent">
                <div class="list" v-for="item in listingList" @click="goDetail(item,item.id)" :key="item.id">
                    <img :src="$util.host +item.cover" alt="" class="img" />
                    <div>
                        <p>{{item.name}}</p>
                        <p>
                            <span>发帖人：<font>{{item.sendUserName}}</font></span> <span>{{item.createTime}}</span>
                        <p v-html="item.content" class="textContent">
                        </p>

                        </p>
                        <p>
                            <span>评论：<font>{{item.commentNum}}</font></span>
                            <span>回复：<font>{{item.replyNum}}</font></span>
                        </p>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination background layout="prev, pager, next" :page-size="rankPage.pageSize" @current-change="changeRank" :total="rankPage.totalRows">
                    </el-pagination>
                    <div class="total">共{{ rankPage.totalRows }}条/{{ rankPage.totalPage }}页</div>
                    <div class="jump">
                        跳转到
                        <input type="number" v-model="rankNum" />
                        页
                    </div>
                    <el-button style="
                        background: #2ea7e0;
                        color: #fff;
                        width: 108px;
                        height: 50px;
                        font-size: 20px;
                    " @click="goRankPage">前往</el-button>
                </div>
            </div>
        </div>
        <div class="tabContentBox" v-if="active == 2">
            <div class="tabContent">
                <div class="list" v-for="item in featuredList" @click="goDetail(item,item.id)" :key="item.id">
                    <img :src="$util.host +item.cover" alt="" class="img" />
                    <div>
                        <p>{{item.name}}</p>
                        <p>
                            <span>发帖人：<font>{{item.sendUserName}}</font></span> <span>{{item.createTime}}</span>
                        </p>
                        <p v-html="item.content" class="textContent">

                        </p>
                        <p>
                            <span>评论：<font>{{item.commentNum}}</font></span>
                            <span>回复：<font>{{item.replyNum}}</font></span>
                        </p>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination background layout="prev, pager, next" :page-size="defaultPage.pageSize" @current-change="changedefault" :total="defaultPage.totalRows">
                    </el-pagination>
                    <div class="total">共{{ defaultPage.totalRows }}条/{{ defaultPage.totalPage }}页</div>
                    <div class="jump">
                        跳转到
                        <input type="number" v-model="defaultNum" />
                        页
                    </div>
                    <el-button style="
                        background: #2ea7e0;
                        color: #fff;
                        width: 108px;
                        height: 50px;
                        font-size: 20px;
                    " @click="godefaultPage">前往</el-button>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Search from "../../components/forumSearch.vue";
import Footer from "../../components/footer.vue";
export default {
    components: { Search, Footer },
    name: "QppIndex",
	metaInfo: {
		title: '企业服务资质体系_知识产权等内容分享平台_企胖胖', // set a title
		meta: [{
				name: 'description',
				content: '企业服务行业内容分享平台、汇集资质体系、工商账税、知识产权、商标、品牌设计、网店交易等行业'
			},
			{
				name: 'keyWords',
				content: '企胖胖，社区论坛，商标，资质体系，品牌设计，网站建设，知识产权，工商财税，ISO体系'
			},
		]
	},
    data() {
        return {
            classText: sessionStorage.getItem("classText"),
            activeName: "默认",
            active: 0,
            currentPage: 5,
            showClass: false,
            tab: ["默认", "排行", "精品"],
            nav: [],
            balnner: [],
            //sessionStorage.getItem("textId")
            classifyId: sessionStorage.getItem("textId"),
            toppingList: [],//置顶信息
            defaultList: [],//默认列表
            featuredList: [],//精选列表
            listingList: [],//排行列表
            articleNum: "",//总发帖
            backNum: "",//总回贴
            shopPage: {}, //需求总页数
            shopNum: "", //分页跳转的页数
            form: {
                //获取所有订单的参数
                pageNo: 1,
                pageSize: 10,
            },
            rankPage: {}, //排行总页数
            rank: {
                pageNo: 1,//排行每页
                pageSize: 10,//排行每页10条

            },
            rankNum: "",
            defaultPage: {}, //默认总页数
            default: {
                pageNo: 1,//默认每页
                pageSize: 10,//排行每页10条

            },
            defaultNum: "",

        };
    },
    mounted() {
        this.getClass()
        this.init()
    },
    methods: {
        // 默认
        changedefault(num) {
            this.default.pageNo = num;
            this.getDefult();
        },
        // 跳转到指定的页数
        godefaultPage() {
            this.default.pageNo = this.defaultNum;
            this.getDefult();
        },
        //排行分页
        changeRank(num) {
            this.rank.pageNo = num;
            this.getListing();
        },
        // 跳转到指定的页数
        goRankPage() {
            this.rank.pageNo = this.rankNum;
            this.getListing();
        },
        //
        // 页数改变是触发
        changePage(num) {
            this.form.pageNo = num;
            this.getDefult();
        },
        // 跳转到指定的页数
        goShopPage() {
            this.form.pageNo = this.shopNum;
            this.getDefult();
        },
        //打开新页面
        toHerf(val) {
            if (val.href !== null) {
                window.open(val.href, '_blank')
            }

        },
        //我要发帖
        toPost() {
            if (sessionStorage.getItem("token")) {
                this.$router.push({ path: '/post' })
            } else {
                this.$message.error("暂未登录,请先登录");
            }
        },

        // 获取轮播图
        getBalnner() {
            this.$util.post('/home/banner', {
                type: '3',
                classifyId: this.classifyId
            }).then(res => {
                console.log(res);
                this.balnner = res.data
            })
        },
        getClass() {
            this.$util.post('/shop/allClassify').then(res => {
                console.log(res);
                if (sessionStorage.getItem("textId") == null) {
                    sessionStorage.setItem("textId", res.data[0].id)
                }
                // 
                this.nav = res.data
                this.classifyId = sessionStorage.getItem("textId")//res.data[0].id
                this.getTopping()
                this.getyFeatured()
                this.getListing()
                this.getDefult()
                this.getBalnner()
            })
        },
        // 置顶列表
        getTopping() {
            this.$util.post('/forum/article/list/top', {
                classifyId: this.classifyId
            }).then(res => {
                if (res.code == 200) {
                    console.log(res);
                    this.toppingList = res.data
                }

            })
        },
        // 默认列表
        getDefult() {
            this.$util.post('/forum/article/list/def', {
                classifyId: this.classifyId,
                pageNo: this.form.pageNo,
                pageSize: this.form.pageSize
            }).then(res => {
                if (res.code == 200) {
                    res.data.forEach(item => {
                        item.content = item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
                            return match.replace("../../../", this.$util.host + '/')
                        });
                    })
                    this.defaultList = res.data
                    this.shopPage = res.pager
                }
            })
        },
        // 精选列表
        getyFeatured() {
            //default
            this.$util.post('/forum/article/list/boutique', {
                classifyId: this.classifyId,
                pageNo: this.default.pageNo,
                pageSize: this.default.pageSize
            }).then(res => {
                if (res.code == 200) {
                    res.data.forEach(item => {
                        item.content = item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
                            return match.replace("../../../", this.$util.host + '/')
                        });
                    })
                    this.featuredList = res.data
                    this.defaultPage = res.pager
                }

            })
        },
        // 排行列表
        getListing() {
            this.$util.post('/forum/article/list/ranking', {
                classifyId: this.classifyId,
                pageNo: this.rank.pageNo,
                pageSize: this.rank.pageSize
            }).then(res => {
                if (res.code == 200) {
                    res.data.forEach(item => {
                        item.content = item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
                            return match.replace("../../../", this.$util.host + '/')
                        });
                    })
                    this.listingList = res.data
                    this.rankPage = res.pager
                }
            })
        },
        navClickFun(item) {
            sessionStorage.setItem("classText", item.name)
            this.classText = item.name
            this.classifyId = item.id
            this.getTopping()
            this.getyFeatured()
            this.getListing()
            this.getDefult()
            this.getBalnner()
        },
        indexChange(item) {
            sessionStorage.setItem("classText", item.name)
            this.classifyId = item.id
            this.classText = item.name
            this.getTopping()
            this.getyFeatured()
            this.getListing()
            this.getDefult()
            this.getBalnner()
        },
        goUstand() {
            this.$router.push({ path: '/understand' })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        //查看详情
        goDetail(val, id) {
            console.log(val);

            sessionStorage.setItem("textId", val.classifyId)
            this.$router.push({ path: "communityDetail", query: { id } });
        },
        changeTab(id) {
            this.active = id;
            console.log(this.active);
        },
        init() {
            this.$util.post('/forum/num').then(res => {
                if (res.code == 200) {
                    console.log(res);
                    this.articleNum = res.data.articleNum
                    this.backNum = res.data.backNum
                }

            })
        }
    },
};
</script>
<style>
.activeClass {
    color: #e40012 !important;
    border-bottom: 2px solid #e40012;
}
.textContent img {
    display: none;
}
.textContent {
    display: block;
    overflow: hidden;
    height: 80px;
}
</style>    
<style lang="scss" scoped>
.communityBox {
    .noneBox {
        position: absolute;
        width: 98px;
        border: 1px solid #d1d1d1;
        right: -30px;
        top: 130px;
        background: #ffffff;
        z-index: 999 !important;
        padding: 20px 0;
        p {
            font-size: 14px;
            padding-bottom: 15px;
            cursor: pointer;
        }
        p:last-child {
            padding-bottom: 0;
        }
    }
    .tabContentBox {
        width: 100%;
        // height: 1700px;
        padding-bottom: 100px;
        background: #f6f6f6;
        .tabContent {
            width: 1200px;
            margin: auto;
            padding-top: 30px;
            .pageInput {
                display: flex;
                justify-content: center;
                padding-top: 38px;
            }
            .list {
                padding: 30px;
                height: 180px;
                background: #ffffff;
                cursor: pointer;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                div {
                    flex: 1;
                    // overflow: hidden;
                    max-height: 198px;
                }
                p:nth-child(1) {
                    color: #222222;
                    font-size: 22px;
                    height: 20px;
                    padding-bottom: 12px;
                    text-overflow: ellipsis;
                    width: 400px;
                    -webkit-box-orient: vertical; /*设置方向*/
                    -webkit-line-clamp: 1; /*设置超过为省略号的行数*/
                    overflow: hidden;
                }
                p:nth-child(2) {
                    display: flex;
                    justify-content: flex-start;
                    font-size: 14px;
                    padding-bottom: 10px;
                    span {
                        color: #666666;
                        padding-right: 74px;
                    }
                    font {
                        color: #222222;
                    }
                }
                p:nth-child(3) {
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 16px;
                    height: 82px;
                    width: 912px;
                    margin-bottom: 25px;
                    /deep/img {
                        width: 60px;
                        height: 60px;
                    }
                    /deep/p {
                        display: block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    /deep/h1 {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                p:nth-child(4) {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 14px;
                    span {
                        color: #666666;
                        padding-right: 34px;
                    }
                    font {
                        color: #222222;
                    }
                }
                .img {
                    width: 198px;
                    height: 198px;
                    margin-right: 20px;
                }
            }
            .pagination {
                width: 1200px;
                margin: 30px auto 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            /deep/.el-pager li {
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 4px !important;
                border: 1px solid #2ea7e0;
                margin: 0 3px;
                color: #2ea7e0 !important;
            }
            /deep/.el-pagination .btn-next,
            /deep/.el-pagination .btn-prev {
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 4px !important;
                // border: 1px solid #2ea7e0;
                background: transparent;
                margin: 0 3px;
            }
            /deep/.el-pagination.is-background
                .el-pager
                li:not(.disabled).active {
                color: #fff !important;
            }
            /deep/.el-pagination .btn-next .el-icon,
            /deep/.el-pagination .btn-prev .el-icon {
                font-size: 24px;
                color: #2ea7e0;
            }
            /deep/.el-pager li.active + li {
                border: 1px solid #2ea7e0;
            }
            .total {
                color: #2ea7e0;
                font-weight: bold;
                font-size: 16px;
            }
            .jump {
                display: flex;
                align-items: center;
                font-size: 16px;
                margin: 0 20px 0 40px;
                color: #2ea7e0;
                input {
                    width: 40px;
                    height: 40px;
                    border: 1px solid #0f6c97;
                    font-size: 14px;
                    color: #2ea7e0;
                    outline: none;
                    margin: 0 10px;
                    border-radius: 4px;
                }
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            input[type="number"] {
                -moz-appearance: textfield;
            }
        }
    }
    .communityContent {
        width: 1200px;
        margin: auto;
        .tableBox {
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
            .tableList {
                display: flex;
                justify-content: flex-start;
                font-size: 22px;
                div {
                    margin-right: 63px;
                    cursor: pointer;
                    padding-bottom: 5px;
                    text-align: center;
                    width: 80px;
                    color: #666666;
                }
            }
            .wantTopost {
                cursor: pointer;
                text-align: center;
                line-height: 36px;
                margin-bottom: 8px;
                width: 109px;
                height: 36px;
                background: rgba(34, 34, 34, 0.06);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
            }
        }
        .recommendBox {
            width: 1200px;
            height: 428px;
            .recommend {
                position: absolute;
                width: 100%;
                left: 0;
                height: 418px;
                background: #fff5f6;

                .fourList {
                    width: 1200px;
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    .list {
                        display: flex;
                        justify-content: flex-start;
                        div {
                            cursor: pointer;
                            padding: 10px 0;
                            p:nth-child(1) {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 180px;
                                height: 20px;
                                margin-bottom: 35px;
                                font-size: 16px;
                            }
                            p:nth-child(2) {
                                color: #222222;
                                font-size: 16px;
                            }
                        }
                    }
                    img {
                        width: 150px;
                        cursor: pointer;
                        margin-right: 20px;
                        height: 150px;
                    }
                }

                .twoList {
                    padding: 50px 0;
                    width: 1200px;
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    .list {
                        display: flex;
                        justify-content: flex-start;
                        div {
                            cursor: pointer;
                            padding: 10px 0;
                            p:nth-child(1) {
                                color: #222222;
                                font-size: 16px;
                                // padding-bottom: 12px;
                                margin-bottom: 12px;
                                -webkit-box-orient: vertical;
                                width: 180px;
                                height: 20px;
                                -webkit-line-clamp: 1;
                                // display: -webkit-box;
                                // text-overflow: ellipsis;
                                // overflow: hidden;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            p:nth-child(2) {
                                color: #222222;
                                font-size: 16px;
                            }
                        }
                    }
                    img {
                        width: 150px;
                        cursor: pointer;
                        margin-right: 20px;
                        height: 150px;
                    }
                }
            }
        }
        .bannerBox {
            margin-bottom: 50px;
            .bannerImg {
                width: 100%;
                height: 400px;
            }
        }
        .communityData {
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 65px;
            width: 100%;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;
            .title {
                color: #222222;
                font-size: 24px;
            }
            .data {
                display: flex;
                justify-content: right;
                div {
                    font-size: 16px;
                    margin-left: 50px;
                    font {
                        color: #999999;
                    }
                    span {
                        color: #222222;
                    }
                }
            }
        }

        .img {
            height: 50px;
            cursor: pointer;
            padding-bottom: 18px;
        }

        .nav {
            padding-bottom: 50px;
            padding-top: 40px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            position: relative;
            p {
                cursor: pointer;
            }
        }
    }
}
</style>
